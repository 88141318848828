<template>
  <en-drawer :model-value="modelValue" title="选择收款单据" direction="btt" size="70%" @close="$emit('update:model-value', false)">
    <flex-box>
      <template #default="{ height }">
        <form-query :model="form.data" :method="table.get">
          <en-form-item label="付款搜索">
            <en-input v-model="form.data.quickSearch" placeholder="单号/车牌号/收款对象"></en-input>
          </en-form-item>
        </form-query>
        <table-dynamic
          code="RCVB"
          :height="height"
          :data="table.data"
          :loading="table.loading"
          :paging="table.paging"
          :method="table.get"
          pagination
          :config="table.config"
          showSelectable
          @selection-change="table.selection.change"
          :ref="setRef('tableRef')"
        >
          <template #INVOICE_IMAGE="{ row }: { row: EnocloudSettlementDefinitions['ReceivableQueryDto'] }">
            <span v-if="row.invoiceImage">有</span>
            <span v-else>-</span>
          </template>
          <template #INVOICE_STATUS="{ row }: { row: EnocloudSettlementDefinitions['ReceivableQueryDto'] }">
            {{ row.invoiced ? '未开票' : '已开票' }}
          </template>
        </table-dynamic>
      </template>
    </flex-box>

    <template #footer>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  props: {
    modelValue: Boolean,
    selectedData: Array as PropType<EnocloudSettlementDefinitions['ReceivableQueryDto'][]>,
    creditBy : String as PropType<string>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    confirm: (value?: EnocloudSettlementDefinitions['ReceivableQueryDto'][][]) => true
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.modelValue) {
          await this.table.get()
          this.nextTick(() => {
            if (!this.selectedData) return
            for (const item of this.table.data) {
              this.refs.tableRef.toggleRowSelection(item, this.selectedData.map((sd) => sd.id).includes(item.id))
            }
          })
        }
      }
    }
  },

  config: {
    children: {
   
      form: {
        data: {
          statusCode: 'C',
          quickSearch: '',
        }
      },
      table: {
        ajax: {
          get: {
            action: 'GET /enocloud/settlement/receivable/query',
            data: 'array',
            loading: true,
            init: true,
            pagination: true,
            params(params) {
              params.payload = Object.assign({creditBy: this.creditBy},this.form.data)
            }
          }
        },
        children: {
          selection: {
            data: [] as EnocloudSettlementDefinitions['ReceivableQueryDto'][],
            change(rows: EnocloudSettlementDefinitions['ReceivableQueryDto'][]) {
              this.table.selection.data = rows
            }
          }
        },
        config: {
          CELLPHONE: {
            header: {
              filter: {
                type: 'text',
                field: 'cellphone'
              }
            }
          },
          CREDIT_BY: {
            header: {
              filter: {
                type: 'text',
                field: 'creditBy'
              }
            }
          },

          SERVICE_ADVISOR: {
            header: {
              filter: {
                type: 'select',
                field: 'advisorName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user',
                    params: (params, value) => (params.payload = { name: value, includingDestroyed: true })
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          INVOICE_STATUS: {
            header: {
              filter: {
                type: 'select',
                field: 'invoiced',
                props: {
                  options: [
                    { message: '已开票', code: 'Y' },
                    { message: '未开票', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          INVOICE_DATE: {
            header: {
              filter: {
                type: 'date',
                field: ['invoiceDateStart', 'invoiceDateEnd'],
                props: { type: 'daterange' }
              }
            }
          },
          INVOICE_NO: {
            header: {
              filter: {
                type: 'text',
                field: 'invoiceNo'
              }
            }
          },
          PREPARED_BY: {
            header: {
              filter: {
                type: 'text',
                field: 'settlementByName'
              }
            }
          },

          INVOICE_TITLE: {
            header: {
              filter: {
                type: 'text',
                field: 'invoiceTitle'
              }
            }
          },
          DOCUMENT_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'comment'
              }
            }
          },
          RECEIPT_PAYMENT_METHOD: {
            header: {
              filter: {
                type: 'select',
                field: 'receiptPaymentMethod',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => (params.paths = ['PAMTMTD'])
                  },
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          INVOICE_TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'invoiceTypeName',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/hint/:hintTypeStr',
                    params: (params) => (params.paths = ['INVCTP'])
                  },
                  props: { label: 'name', value: 'name' }
                }
              }
            }
          },
          SERVICE_CATEGORY: {
            header: {
              filter: {
                type: 'select',
                field: 'serviceCategoryId',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/service/category',
                    params: (params, value) => (params.payload = { name: value })
                  },
                  remote: true,
                  props: { label: 'name', value: 'id' }
                }
              }
            }
          },
          SERVICE_SETTLEMENT_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['settlementStartDate', 'settlementEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          RECEIPT_BYS: {
            header: {
              filter: {
                type: 'select',
                field: 'receiptBy',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/user'
                  },
                  props: { label: 'name', value: 'id' },
                  remote: true
                }
              }
            }
          },
          INVOICE_IMAGE: {
            header: {
              filter: {
                type: 'select',
                field: 'invoiceImg',
                props: {
                  options: [
                    { message: '有', code: 'Y' },
                    { message: '无', code: 'N' }
                  ],
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          },
          SETTLED_DATETIME: {
            header: {
              filter: {
                type: 'date',
                field: ['settledStartDate', 'settledEndDate'],
                props: { type: 'daterange' }
              }
            }
          },
          RECEIPT_COMMENT: {
            header: {
              filter: {
                type: 'text',
                field: 'receiptComment'
              }
            }
          },
          TYPE: {
            header: {
              filter: {
                type: 'select',
                field: 'typeCode',
                props: {
                  ajax: {
                    action: 'GET /enocloud/common/lookup/:lookupType',
                    params: (params) => ((params.paths = ['RVBTYPE']), (params.payload = { excludes: ['POL'] }))
                  },
                  props: { label: 'message', value: 'code' }
                }
              }
            }
          }
        }
      },
      footer: {
        confirm: {
          async click() {
            this.emit('confirm', this.table.selection.data)
            this.emit('update:model-value', false)
          }
        }
      }
    }
  }
})
</script>
